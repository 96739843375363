import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {toast} from "react-toastify"
import { useFetchQuizQuestions } from '../../../hooks/api/fetch';
import { useSubmitQuiz } from '../../../hooks/api/post';
import useDecrypt from '../../../hooks/utils/useDecrypt';
export default function useQuizLogic() {
  
  const { decrypt } = useDecrypt();
  
  const navigate = useNavigate()
  const [searchParams, setSearchParams]= useSearchParams()
  const quiz_id = searchParams.get("quiz_id") || '63522363-7402-4f9c-97b2-258f0b62a613'
  const user_id= JSON.parse(sessionStorage.getItem("ccAuth"))?.id
  const questions = useFetchQuizQuestions({
    id:quiz_id
  })
  if (questions.isError) {
    toast.dismiss()
    toast.error(questions?.error?.response?.data?.message)
       navigate("../onboarding", {relative:"path"})
  }
  const [data, setData] = useState( questions?.data?.data?.questions||[])
  
  
  useEffect(() => {
    setData(data => questions?.data?.data?.questions)
    setActiveQuiz(data=>questions?.data?.data?.questions?.[activeQuizIndex])
    return ()=>setData([])
  }, [questions.data])

  const submitQuiz= useSubmitQuiz()
  const {id}= useParams()
  const [activeQuizIndex, setActiveQuizIndex] = useState(0);
  const [activeQuiz, setActiveQuiz] = useState(data?.[activeQuizIndex] || []);
  const [selectedOption, setSelectedOption] = useState("");
  const [showModal, setShowModal] = useState(false);
  const gameTime = 59;
  const [timeLeft, setTimeLeft] = useState(gameTime);
  const [pauseTime, setPauseTime] = useState(false);
const [result, setResult]= useState([])
const [testData, setTestData]= useState("")

 

function handleNext(_selectedOption) {
   console.log({_selectedOption, selectedOption})
    handleResult(_selectedOption)
    setPauseTime(true);
    setShowModal(true);
    // setSelectedOption("") 
   
  }
  function handleSkip(_selectedOption) {
    setPauseTime(true);
    // setShowModal(true);
    handleResult(_selectedOption)
    changeQuestion()
    
    // setSelectedOption("") 
   
  }


  function handleResult(_selectedOption) {
    const score= {type:_selectedOption === decrypt(activeQuiz?.question_answer || "")?'correct':'wrong', time:gameTime-timeLeft, id:activeQuiz?.question_id, question_answer:_selectedOption || ""}
  
    setResult(result=>[...result, score])
  }
  
  function handleSubmitQuiz() {
    toast.dismiss()
   let loading = toast.loading("Submitting Your Score")
   console.log({result})
   setActiveQuizIndex(0);
  setActiveQuiz(data[0]);
  setTimeLeft(59);
  let correct = result.reduce((a, b) => b.type === "correct" ? a + 1 : a, 0)
  let wrong = result.reduce((a, b) => b.type === "wrong" ? a + 1 : a, 0)
   submitQuiz.mutate({
      quiz_id,
      user_id,
      questions:result
    }, {
      onSuccess: (response) => {
       
        toast.dismiss(loading)
        navigate(`/event/${id}/quizcompleted?quiz_id=${quiz_id}&score=${response?.data?.point}&rank=${response?.rank}&attempts=${response?.attempts}&correct=${correct}&wrong=${wrong}`)
        
      },
      onError(err){
console.log({err})
       toast.dismiss(loading)
       navigate(`/event/${id}/quizcompleted?quiz_id=${quiz_id}&score=&rank=&attempts=&correct=${correct}&wrong=${wrong}`)

     }
   })
  }




  useEffect(() => {
    function getTimeLeft() {
      if (pauseTime || timeLeft < 1) {
        return;
      }
      setTimeLeft(timeLeft - 1);
      if (timeLeft <= 1) {
        handleNext();
      }
    }
    const timer = setInterval(getTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [pauseTime, timeLeft]);

  function handleCloseModal() {
  
    if (!submitQuiz.isLoading) {
  
      setShowModal(false)
}
      let index = activeQuizIndex + 1;
      setSelectedOption("");
      setPauseTime(false);
      if (index >= data?.length) {
        // setActiveQuizIndex(0);
        // setActiveQuiz(data[0]);
        // setTimeLeft(59);
        handleSubmitQuiz()
         return;
      }
      setActiveQuizIndex(index);
      setActiveQuiz(data[index]);
      setTimeLeft(59);
      // setSelectedOption("") 
    
  }

  function changeQuestion(){
    let index = activeQuizIndex + 1;
    // setSelectedOption("");
    setPauseTime(false);
    if (index >= data?.length) {
      // setActiveQuizIndex(0);
      // setActiveQuiz(data[0]);
      // setTimeLeft(59);
      handleSubmitQuiz()
       return;
    }
    setActiveQuizIndex(index);
    setActiveQuiz(data[index]);
    setTimeLeft(59);
    // setSelectedOption("") 
  }
  

  return {
    activeQuizIndex,
    activeQuiz,
      selectedOption,
    setSelectedOption,
    setShowModal,
    showModal,
    timeLeft,
    handleNext,
    handleSkip,
    handleCloseModal,
    result, setResult,
    questions,
    decrypt,
    data,
    testData, setTestData
  };
}
