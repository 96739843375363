import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Image from "material-ui-image";
import Card from "../../components/cardLayout/Card";
import AuthLayout from "../../components/layout/NewAuthLayout";
import Progress from "./components/Progress";
import Timer from "./components/Timer";
import Question from "./components/Question";
import ArrowForward from "../../assets/Icons2/arrow-square-right.png";
import BackgroundImage from "../../assets/Images2/background2.png";
import Header from "./components/Header";
// import data from "./mock/data2.json";
import CorrectModal from "./components/CorrectModal";
import useQuizLogic from "./hooks/useQuizPlay";
import { useNavigate } from "react-router-dom";
function QuizPlay() {
  const registerTag = async () => {
    // Wait for the service worker.
    const registration = await navigator.serviceWorker.ready
    // Using the registration, register a tag.
    try{
      await registration.sync.register('submit-qwiz')

      console.log("service worker added for submit quiz", )
    }
    catch(ex){
      console.log("unable to register ", ex)
    }
  }
  registerTag()
  const {
    activeQuizIndex,
    activeQuiz,
    selectedOption,
    setSelectedOption,
    showModal,
    timeLeft,
    handleNext,
    handleSkip,
    setShowModal,
    handleCloseModal,
    result,
    setResult,
    questions,
    decrypt,
    data,testData, setTestData

  } = useQuizLogic();

  if (questions.isLoading) {
    return <>Loading</>;
  }
  if (questions.isError) {
    // todo : navigate back to homepage

    return <>An Error Occured</>;
  }

  return (
    <Box
      sx={{
        background: `url(${BackgroundImage}) no-repeat`,
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Header title={"Quiz Name"}  />
      <Box p={2}>
        <Stack direction="row" justifyContent="space-between">
          <Progress
            currentStep={activeQuizIndex + 1}
            totalStep={data?.length}
          />
          <Timer time={timeLeft} />
        </Stack>
        <Box mt={2}>

      
           <Question
          setTestData={setTestData}
            question={decrypt(activeQuiz?.question || "")}
            handleNext={handleNext}
           selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            options={activeQuiz?.options?.map((opt) => {
              opt.value = opt.question_option;
              opt.label = opt.question_option;
              return opt;
            })}
          />
        </Box>
       
        <Box mt={3} sx={{ display: "flex", justifyContent: "left" }}>
          <Button onClick={()=>handleSkip("")}>
            <Card small sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body1"
                mr={0.5}
                fontSize={12}
                fontStyle={800}
              >
                Skip
              </Typography>
              <Box height={24} width={24}>
                <Image src={ArrowForward} />
              </Box>
            </Card>
          </Button>
        </Box>
      </Box>

      {/* Correct / Wrong Modal  */}
      
      
      {showModal && (
        <CorrectModal
          open={showModal}
          handleClose={
         
            handleCloseModal
          }
          wrong={selectedOption !== decrypt(activeQuiz?.question_answer || "")}
        />
      )}
    </Box>
  );
}

export default QuizPlay;
